<template>
  <a-card class="card" :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="订单号">
              <a-input v-model.trim="queryParam.order_num" placeholder="根据订单号搜索" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="购买单位">
              <a-select
                showSearch
                label-in-value
                allowClear
                :filter-option="false"
                :value="customerInfo"
                :not-found-content="fetching ? undefined : null"
                placeholder="请选择购买单位"
                style="width: 100%"
                mode="multiple"
                @search="getDepartmentCustomer"
                @change="changeCustomerInfo"
              >
                <template v-if="fetching" #notFoundContent>
                  <a-spin size="small" />
                </template>
                <a-select-option v-for="op in buyerOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="费用状态">
              <a-select
                allowClear
                placeholder="请选择费用状态"
                style="width: 100%"
                v-model="queryParam.fee_status"
                mode="multiple"
              >
                <a-select-option v-for="op in feeStatusList" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="合计金额">
              <a-input-number v-model="queryParam.total_amount" placeholder="输入合计金额搜索" style="width: 100%" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="订单类型">
              <a-select v-model="queryParam.bill_type" placeholder="请选择订单类型" allowClear>
                <a-select-option :value="1">出货</a-select-option>
                <a-select-option :value="2">代销</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="创建时间">
              <a-range-picker @change="onDateChange" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="品名">
              <a-input v-model.trim="queryParam.good_name" placeholder="根据品名进行搜索" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="交易状态">
              <a-select v-model="queryParam.trade_status" placeholder="请选择交易状态" allowClear>
                <a-select-option v-for="(item, index) in tradeStatusList" :key="index" :value="item.value">{{ item.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="订购人">
              <a-input v-model.trim="queryParam.order_person" placeholder="根据订购人进行搜索" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <a-button type="query" icon="search" @click="querySubmit">查询</a-button>
      <a-button type="primary" :disabled="!selectedRowKeys.length" @click="handleVerifyOrAudit('basic_audit')">审核</a-button>
      <a-button type="primary" :disabled="!selectedRowKeys.length" @click="handleVerifyOrAudit('undo_audit')">反审核</a-button>
      <a-button type="primary" :disabled="!selectedRowKeys.length" @click="handleBillApply()">开票申请</a-button>
      <a-button type="primary" @click="handleExport">导出对账单</a-button>
      <a-popover
        v-model="popVisible"
        title="选择月份"
        trigger="click">
        <a slot="content">
          <p><a-month-picker v-model="exportDate" style="width: 200px;" format="YYYY/MM" placeholder="请选择月份"></a-month-picker></p>
          <p style="text-align: right"><a-button type="primary" @click="handelInsideConsumExport">确定</a-button></p>
        </a>
        <a-button type="primary">内部消费统计导出</a-button>
      </a-popover>
    </div>
    <s-table
      ref="table"
      size="small"
      :scroll="{ x: 'max-content' }"
      :rowKey="function(record) {return record.order_num}"
      :columns="columns"
      :data="loadData"
      :alert="{
        show: true,
        msg: showTotal
      }"
      :rowSelection="{
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange,
        onSelect: this.selectItem,
        onSelectAll: this.selectAllItem
      }">
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="sale_num_title">{{ `订单号` }}</span>
      <span slot="sale_num" slot-scope="text, record">
        {{ record.order_num }}
      </span>
      <span slot="sale_name_title">{{ '购买单位' }}</span>
      <span slot="sale_name" slot-scope="text, record">
        {{ record.customer_name }}
      </span>
      <span slot="sale_cashRecord_title">{{ '交易状态' }}</span>
      <span slot="cashRecord" slot-scope="text, record">
        <span v-for="item in record.cash_record_list" :key="item.id" style="display:block">{{ getCashRecordInfo(item) }}</span>
      </span>
      <span slot="sale_order_person">{{ '订购人' }}</span>
      <span slot="order_person" slot-scope="text, record">
        {{ record.order_person }}
      </span>
      <span slot="sale_order_phone">{{ '订购电话' }}</span>
      <span slot="order_phone" slot-scope="text, record">
        {{ record.order_phone }}
      </span>
      <div slot="cashSlot" slot-scope="text">
        <div v-for="item in text" :key="item.id">
          {{moment(item.pay_date).format('YYYY/MM/DD')}}
        </div>
      </div>
      <div slot="feeItem" slot-scope="fee">
        <div v-for="item in fee" :key="item.id" :class="feeStatusColor(item.fee_status)">
          <a-popover trigger="click" v-if="item.deny_reason">
            <a-tag :color="feeStatusColor(item.fee_status)" style="margin-bottom:2px;">
              {{ GLOBAL.feeStatusMaps[item.fee_status] ? GLOBAL.feeStatusMaps[item.fee_status].name : '' }}
            </a-tag>
            <template slot="content">
              <div v-for="(reason, index) in item.deny_reason" :key="index">
                <div>驳回人:<a style="margin-right:10px;">{{ reason.deny_person_name }}</a>驳回原因:<a style="margin-right:10px;">{{ reason.deny_reason }}</a>驳回时间:<a>{{ reason.deny_date }}</a></div>
              </div>
            </template>
          </a-popover>
          <a-tag :color="feeStatusColor(item.fee_status)" style="margin-bottom:2px;" v-else>
            {{ GLOBAL.feeStatusMaps[item.fee_status] ? GLOBAL.feeStatusMaps[item.fee_status].name : '' }}
          </a-tag>
          <span v-if="item.charge_type === 1">{{ `${item.good_name}/${item.unit}/${item.good_spec}` }} ~ 数量: {{ item.good_num }} 现金:{{ item.total_money }}</span>
          <span v-else-if="item.charge_type === 2">{{ `${item.good_name}/${item.unit}/${item.good_spec}` }} ~ 数量: {{ item.good_num }} 月结:{{ item.total_money }}</span>
          <span v-else-if="item.charge_type === 3">{{ `${item.good_name}/${item.unit}/${item.good_spec}` }} ~ 数量: {{ item.good_num }} 现金,月结:{{ item.total_money }}</span>
        </div>
      </div>
    </s-table>

    <a-modal
      :visible="applyFormVisible"
      :confirmLoading="confirmLoading"
      title="开票申请"
      :width="980"
      @cancel="handleApplyFormCancel"
      @ok="handleApplyFormSubmit"
    >
      <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
        <a-row :gutter="20">
          <a-divider orientation="left">订单信息</a-divider>
          <a-col :span="8">
            <a-form-model-item label="委托单位">
              <a-input v-model="bizAuditForm.customer" :disabled="true" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="所属部门">
              <a-input v-model="bizAuditForm.department" :disabled="true" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="录入人">
              <a-input v-model="bizAuditForm.creator" :disabled="true" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="申请时间">
              <a-input v-model="bizAuditForm.applyTime" :disabled="true" />
            </a-form-model-item>
          </a-col>
          <a-divider orientation="left">费用信息
            <a-tag color="#f50" style="margin-left:10px;">合计:{{ totalAmount.toFixed(2) }}</a-tag>
          </a-divider>
          <a-col :span="24">
            <edit-table
              :columns="feeInfoColumns"
              :sourceData="feeInfoData"
              @getTableDate="getFeeInfoTableDate"
              :disabled="true"></edit-table>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="申请备注">
              <a-input v-model="form.apply_remark"></a-input>
            </a-form-model-item>
          </a-col>
          <a-divider orientation="left">开票信息</a-divider>
          <a-col :span="12">
            <a-form-model-item label="委托单位/客户名称" prop="customer_name">
              <a-select
                showSearch
                label-in-value
                allowClear
                placeholder="请选择委托客户"
                style="width: 100%"
                :value="customerInfo2"
                :filter-option="false"
                :not-found-content="fetching2 ? undefined : null"
                @search="getDepartmentCustomer2"
                @change="changeCustomerInfo2"
              >
                <template v-if="fetching2" #notFoundContent>
                  <a-spin size="small" />
                </template>
                <a-select-option v-for="op in buyerOps2" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="纳税人识别号" prop="tax_no">
              <a-input v-model="form.tax_no" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="地址">
              <a-input v-model="form.address" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="电话">
              <a-input v-model="form.mobile" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="银行卡号">
              <a-input v-model="form.bank_card_no" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="开户银行">
              <a-input v-model="form.bank" />
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="税率" prop="tax_rate">
              <a-select v-model="form.tax_rate">
                <a-select-option :value="item.value" v-for="item in taxRateOps" :key="item.value">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="发票类型" prop="invoice_type">
              <a-select
                v-model="form.invoice_type"
              >
                <a-select-option v-for="op in invoiceTypeList" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="发票备注">
              <a-input v-model="form.invoice_remark"></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
  </a-card>
</template>

<script>
import { STable, EditTable } from '@/components'
import { getCustomerInfo, getCustomer, downloadExcel } from '@/api/common'
import moment from 'moment'
import { getSellSaleFinancePage, getSellSaleFinanceTotal, wmsSaleFinanceChargeFunc, exportWmsSaleFinance } from '@/api/wms'
import { getchargeTax } from '@/api/fms'
import debounce from 'lodash/debounce'
import { exportInsideConsumptionSheet } from '@/api/wms/sale'

export default {
  components: {
    STable, EditTable
  },
  data() {
    this.lastFetchId = 0;
    this.lastFetchId2 = 0;
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800);
    this.getDepartmentCustomer2 = debounce(this.getDepartmentCustomer2, 800);
    return {
      totalAmount: 0,
      checkedList: [],
      indeterminate: false,
      checkAll: true,
      queryParam: {
        type: 1
      },
      confirmLoading: false,
      chargeType: {
        1: { value: 1, name: '现金' },
        2: { value: 2, name: '月结' },
        3: { value: 3, name: '现金,月结' }
      },
      feeStatusList: [
        { value: 1, name: `待审核` },
        { value: 2, name: `业务已审核` },
        { value: 3, name: `商务待审核` },
        { value: 4, name: `商务审核被驳回` },
        { value: 5, name: `待开票` },
        { value: 6, name: `已开票` }
      ],
      invoiceTypeList: [
        { value: 1, name: '专用发票' },
        { value: 2, name: '普通发票' }
      ],
      customerOps: [],
      buyerOps: [],
      buyerOps2: [],
      taxRateOps: [],
      tradeStatusList: [
        { name: '待支付', value: 0 },
        { name: '已完成', value: 1 }
      ],
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center'
        },
        {
          title: '类型',
          dataIndex: 'type',
          customRender: text => {
            switch (text) {
              case 1:return '出货'
              case 2:return '代销'
              default: return ''
            }
          }
        },
        {
          slots: { title: 'sale_num_title' },
          scopedSlots: { customRender: 'sale_num' }
        },
        {
          slots: { title: 'sale_name_title' },
          scopedSlots: { customRender: 'sale_name' }
        },
        {
          slots: { title: 'sale_cashRecord_title' },
          scopedSlots: { customRender: 'cashRecord' }
        },
        {
          title: '费用',
          dataIndex: 'fee_info_list',
          scopedSlots: { customRender: 'feeItem' }
        },
        {
          title: '交易日期',
          dataIndex: 'cash_record_list',
          scopedSlots: { customRender: 'cashSlot' }
        },
        {
          slots: { title: 'sale_order_person' },
          scopedSlots: { customRender: 'order_person' }
        },
        {
          slots: { title: 'sale_order_phone' },
          scopedSlots: { customRender: 'order_phone' }
        },
        {
          title: '创建时间',
          dataIndex: 'order_date'
        },
        {
          title: '合计',
          dataIndex: 'total'
        },
        {
          title: '备注',
          dataIndex: 'remark'
        }
      ],
      loadData: parameter => {
        return getSellSaleFinancePage(Object.assign(parameter, this.queryParam)).then(res => {
          // if (this.chargeTotal && this.chargeTotal !== 0) {
          //   this.showTotal = '应收总价合计' + this.chargeTotal.toFixed(2);
          // } else {
          //   if (res.total_row) {
          //     this.showTotal = '应收总价合计:' + res.total_row.total.toFixed(2);
          //   }
          // }
          return res
        })
      },
      showTotal: '应收总价合计:0',
      chargeTotal: 0, // 应收合计总价
      selectedRowKeys: [],
      selectedRows: [],
      applyFormVisible: false,
      form: {
        apply_remark: '',
        customer_name: '',
        customer_id: '',
        tax_no: '',
        address: '',
        mobile: '',
        bank: '',
        bank_card_no: '',
        remark: '',
        invoice_type: null
      },
      bizAuditForm: {
        customer: '',
        department: '进销存',
        creator: '',
        applyTime: moment().format('L'),
        fees: null,
        total: null
      },
      bankAccountOps: [],
      rules: {
        customer_name: [{ required: true, message: '请输入委托单位/客户名称', trigger: 'blur' }],
        tax_rate: [{ required: true, message: '请选择税率', trigger: 'blur' }],
        invoice_type: [{ required: true, message: '请选择发票类型', trigger: 'blur' }]
      },
      feeInfoColumns: [
        {
          title: `货物名称`,
          dataIndex: `good_name`,
          extra: {
            type: `text`
          }
        },
        {
          title: `付款方式`,
          dataIndex: `charge_type`,
          extra: {
            type: `text`
          }
        },
        {
          title: `单价`,
          dataIndex: `money`,
          extra: {
            type: `text`
          }
        },
        {
          title: `合计`,
          dataIndex: `total_money`,
          extra: {
            type: `text`
          }
        },
        {
          title: `费用状态`,
          dataIndex: `fee_status`,
          extra: {
            type: `text`
          }
        },
        {
          title: `数量`,
          dataIndex: `good_num`,
          width: 120,
          extra: {
            type: `number`,
            extraFunc: this.calcTotal
          }
        }
      ],
      feeInfoData: [],
      customerInfo: undefined,
      fetching: false,
      popVisible: false,
      exportDate: null,
      customerInfo2: undefined,
      fetching2: false
    }
  },
  created() {
    getchargeTax().then(v => {
      this.taxRateOps = v
    })
    this.getChargeTotal()
  },
  watch: {
    selectedRows: function() {
      if (this.selectedRows.length !== 0) {
        this.chargeTotal = 0;
        const data = this.queryParam['fee_status'];
        this.selectedRows.forEach(v => {
          v.fee_info_list.forEach(fee => {
            if (data && data.length > 0) {
              if (data.includes(fee.fee_status)) {
                this.chargeTotal += fee.total_money;
              }
            } else {
              this.chargeTotal += fee.total_money;
            }
          });
        });
      } else {
        this.chargeTotal = 0;
      }
      this.showTotal = '应收总价合计:' + this.chargeTotal.toFixed(2);
    }
  },
  methods: {
    moment,
    // 查询
    querySubmit() {
      this.$refs.table.refresh(true)
      this.getChargeTotal()
    },
    getChargeTotal() {
      getSellSaleFinanceTotal(this.queryParam).then(v => {
        this.showTotal = '应收总价合计:' + v.toFixed(2)
      })
    },
    getCashRecordInfo(record) {
      let result = '';
      if (record.cash_status === 1) {
        result = '已完成';
      } else {
        result = '待支付';
      }
      return result;
    },
    getDepartmentCustomer2(value) {
      this.lastFetchId2 += 1;
      const fetchId2 = this.lastFetchId2;
      this.buyerOps2 = [];
      this.fetching2 = true;
      getCustomer({
        department: 8,
        customerName: value,
        excludeBlack: 'Y'
      }).then(res => {
        if (fetchId2 !== this.lastFetchId2) {
          return;
        }
        this.buyerOps2 = res;
        this.fetching2 = false;
      })
    },
    changeCustomerInfo2(value) {
      this.customerInfo2 = value;
      this.form.customer_name = value !== undefined ? value.label : null;
      this.buyerOps2.forEach(item => {
        if (item.value === value.key) {
          this.form.customer_id = value.key
          this.form.tax_no = item.taxNum;
          this.form.address = item.street;
          this.form.mobile = item.phone;
          this.form.bank_card_no = item.account;
          this.form.bank = item.bankName;
        }
      });
      this.buyerOps2 = [];
      this.fetching2 = false;
    },
    handelInsideConsumExport() {
      if (this.exportDate) {
        const param = {
          year: this.exportDate.year(),
          month: this.exportDate.month() + 1
        }
        exportInsideConsumptionSheet(param).then(res => {
          if (res !== null) {
            const fileInfo = { fileName: res }
            downloadExcel(fileInfo).then(downRes => {
              const data = downRes.data
              let fileName = ''
              const contentDisposition = downRes.headers['content-disposition']
              if (contentDisposition) {
                fileName = decodeURI(escape(contentDisposition.split('=')[1]))
              }
              const url = window.URL.createObjectURL(
                new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
              )
              const link = document.createElement('a')
              link.style.display = 'none'
              link.href = url
              link.setAttribute('download', fileName)
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            })
          }
        }).finally(_ => {
          this.popVisible = false;
        })
      } else {
        this.$message.warning('请选择导出月份');
      }
    },
    selectItem(record, selected, selectedRows) {
      if (selected) {
        let flag = true
        for (let index = 0; index < this.selectedRows.length; index++) {
          if (this.selectedRows[index].order_num === record.order_num) {
            flag = false
          }
        }
        if (flag) {
          this.selectedRows.push(record)
        }
      } else {
        for (let index = 0; index < this.selectedRows.length; index++) {
          if (this.selectedRows[index].order_num === record.order_num) {
            this.selectedRows.splice(index, 1)
          }
        }
      }
    },
    selectAllItem(selected, selectedRows, changeRows) {
      if (selected) {
        changeRows.forEach(data => {
          let flag = true
          this.selectedRows.forEach(item => {
            if (data.order_num === item.order_num) {
              flag = false
            }
          })
          if (flag) {
            this.selectedRows.push(data)
          }
        })
      } else {
        for (const item of changeRows) {
          for (let index = 0; index < this.selectedRows.length; index++) {
            if (item.order_num === this.selectedRows[index].order_num) {
              this.selectedRows.splice(index, 1)
            }
          }
        }
      }
    },
    onDateChange(date, dateString) {
      if (dateString[0]) {
        this.queryParam['begin_time'] = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss');
        this.queryParam['end_time'] = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss');
      } else {
        this.queryParam['begin_time'] = null;
        this.queryParam['end_time'] = null;
      }
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.buyerOps = [];
      this.fetching = true;
      getCustomer({
        department: 8,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.buyerOps = res
        this.fetching = false;
      })
    },
    changeCustomerInfo(value) {
      this.customerInfo = value;
      const ids = [];
      if (value.length !== 0) {
        value.forEach(item => ids.push(item.key));
      }
      this.queryParam['buyer_ids'] = ids;
      this.buyerOps = [];
      this.fetching = false;
    },
    calcTotal(value, newData, target) {
      if (target.money && target.good_num) {
        target['total_money'] = (target.money * target.good_num).toFixed(2)
      }
      let money = 0;
      this.feeInfoData.forEach(v => {
        money += parseFloat(v.total_money);
      });
      this.totalAmount = money;
      return newData
    },
    getFeeInfoTableDate(data) {
      this.feeInfoData = data;

      let sum = 0;
      data.forEach(fee => {
        sum += parseFloat(fee.total_money);
      });
      this.totalAmount = sum;
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleVerifyOrAudit(operate) {
      // 业务审核
      const data = []
      this.selectedRows.forEach(v => {
        v.fee_info_list.forEach(fee => {
          switch (operate) {
            // 审核
            case 'basic_audit':
              if (fee.fee_status === 1) {
                data.push({
                  bill_id: fee.bill_id,
                  bill_good_id: fee.bill_good_id,
                  bill_type: fee.bill_type
                });
              }
              break;
            // 反审核
            case 'undo_audit':
              if (fee.fee_status === 2) {
                data.push({
                  bill_id: fee.bill_id,
                  bill_good_id: fee.bill_good_id,
                  bill_type: fee.bill_type
                });
              }
              break;
          }
        })
      })
      if (data.length) {
        wmsSaleFinanceChargeFunc(operate, data).then(_ => {
          this.$notification['success']({
            message: '提示',
            description: '操作成功'
          });
          this.$refs.table.refresh(false);

          // 操作成功后动态将选中的 row 数据更新方便操作
          this.selectedRows.forEach(v => {
            if (operate === 'basic_audit') {
              // 审核
              v.fee_info_list.forEach(fee => {
                if (fee.fee_status === 1) {
                  fee.fee_status = 2;
                }
              });
            } else {
              // 反审核
              v.fee_info_list.forEach(fee => {
                if (fee.fee_status === 2) {
                  fee.fee_status = 1;
                }
              });
            }
          });
        });
      } else {
        this.$message.warning(operate === 'basic_audit' ? '请选择已对账待审核的数据' : '请选择业务已审核的数据')
      }
    },
    handleBillApply() {
      const names = this.selectedRows.map(r => {
        return r.supplier_name || r.buyer_name
      })
      const uniqueNames = Array.from(new Set(names))
      if (uniqueNames.length > 1) {
        this.$message.warning('请选择同一客户信息')
      } else {
        let flag = false
        let total = 0
        this.selectedRows.forEach(row => {
          const data = row.fee_info_list && row.fee_info_list.filter(e => e.fee_status === 2 || e.fee_status === 4).map(e => {
            total += e.total_money ? e.total_money : 0;
            if (e.fee_status === 2 || e.fee_status === 4) {
              flag = true
            }
            return {
              good_name: e.good_name,
              charge_type: e.charge_type === 1 ? `现金` : `月结`,
              money: e.money.toFixed(2),
              total_money: e.total_money.toFixed(2),
              fee_status: this.GLOBAL.feeStatusMaps[e.fee_status] ? this.GLOBAL.feeStatusMaps[e.fee_status].name : '',
              good_num: e.good_num,
              bill_id: e.bill_id,
              bill_good_id: e.bill_good_id,
              bill_type: row.type
            }
          });
          this.feeInfoData = this.feeInfoData.concat(data);
        });
        if (flag) {
          // 查询客户相关信息
          this.bankAccountOps = []
          this.getCustomerOps(this.selectedRows[0].customer_name, this.selectedRows[0].customer_id)
          this.bizAuditForm = {
            ...this.bizAuditForm,
            customer: this.selectedRows[0].customer_name,
            total: total,
            creator: this.selectedRows[0].creator
          }
          this.totalAmount = total
          this.applyFormVisible = true
        } else {
          this.$message.warning('没有可提交的费用,费用状态必须为业务已审核或商务审核被驳回')
        }
      }
    },
    getCustomerOps(name, id) {
      getCustomerInfo({
        department: null,
        customerName: name,
        customerId: id
      }).then(res => {
        res.forEach(data => {
          this.customerInfo2 = {
            key: typeof data.value !== 'undefined' ? data.value : '',
            label: typeof data.name !== 'undefined' ? data.name : ''
          }
          this.form.customer_id = typeof data.value !== 'undefined' ? data.value : ''
          this.form.customer_name = typeof data.name !== 'undefined' ? data.name : ''
          this.form.tax_no = typeof data.taxNum !== 'undefined' ? data.taxNum : ''
          this.form.address = typeof data.street !== 'undefined' ? data.street : ''
          this.form.mobile = typeof data.phone !== 'undefined' ? data.phone : ''
          if (typeof data.bankAccount !== 'undefined') {
            this.bankAccountOps = data.bankAccount
            this.form.bank_card_no = typeof data.bankAccount[0].account !== 'undefined' ? data.bankAccount[0].account : ''
            this.form.bank = typeof data.bankAccount[0].bank_name !== 'undefined' ? data.bankAccount[0].bank_name : ''
          }
        })
      })
    },
    bankAccountChange(data) {
      this.bankAccountOps.forEach(item => {
        if (item.account === data) {
          this.form.bank = item.bank_name
        }
      })
    },
    handleApplyFormCancel() {
      this.applyFormVisible = false;
      this.customerInfo2 = undefined;
      this.form = {};
      this.feeInfoData = [];
    },
    handleApplyFormSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.confirmLoading = true;
          const data = [];
          this.feeInfoData.forEach(fee => {
            data.push({
              bill_id: fee.bill_id,
              bill_good_id: fee.bill_good_id,
              bill_type: fee.bill_type,
              good_num: fee.good_num
            });
          })
          wmsSaleFinanceChargeFunc('apply_ticket', {
            ...this.form,
            fee_infos: data
          }).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '操作成功'
            })
            this.selectedRows = [];
            this.selectedRowKeys = [];
            this.$refs.table.refresh(false);
            this.handleApplyFormCancel();
          }).finally(_ => {
            this.confirmLoading = false;
          });
        }
      });
    },
    // 导出对账单
    handleExport() {
      const orderNumList = []
      this.selectedRows.forEach(v => orderNumList.push(v.order_num))
      if (orderNumList.length === 0) {
        this.$message.warning('请选择至少一条记录进行导出')
        return
      }
      this.queryParam['order_num_list'] = orderNumList
      exportWmsSaleFinance(this.queryParam).then(res => {
        if (res !== null) {
          const fileInfo = {
            fileName: res
          }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        }
      })
    },
    feeStatusColor(value) {
      switch (value) {
        case 1:
          return '#faad14'
        case 2:
          return '#1890ff'
        case 3:
          return '#4cd964'
        case 4:
          return '#ff4d4f'
        default:
          return '#4cd964'
      }
    }
  }
}
</script>
